import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';

export default function ErrorAlert() {
  const { t } = useTranslation();
  const snackbarPosition: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
  };
  const refreshAction = (
    <Button color="error" onClick={() => window.location.reload()}>
      {t('snackbar.refresh-action')}
    </Button>
  );

  return (
    <Snackbar
      open
      message={t('snackbar.error')}
      autoHideDuration={5000}
      anchorOrigin={snackbarPosition}
      action={refreshAction}
      TransitionComponent={Fade}
    />
  );
}
