import './Splashscreen.css';

function Splashscreen() {
  return (
    <div className="splashscreen-container">
      <img
        className="splashscreen-logo fade-in"
        src={process.env.PUBLIC_URL + '/TotalEnergies-logo.svg'}
        alt="TotalEnergies loading screen"
      />
    </div>
  );
}

export default Splashscreen;
