import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';

export const initialize = () => {
  const reactPlugin = new ReactPlugin();
  const appInsightsKey = process.env.REACT_APP_INSTRUMENTATION_KEY || '';
  const ai = new ApplicationInsights({
    config: {
      instrumentationKey: appInsightsKey,
      disableFetchTracking: true,
      disableAjaxTracking: true,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin as unknown as ITelemetryPlugin]
    }
  });

  ai.loadAppInsights();
  ai.trackPageView();

  return { reactPlugin, ai };
};
